:root.light {
  --main-contrast: var(--dark-lead);
  --background-lowlight: var(--white-stone);
  --background-main: var(--white-smoke);
  --background-secondary: var(--white);
  --background-highlight: var(--white);

  --main-invert: var(--white-ghost);
  --background-invert: var(--dark-lead);
  --cta-tertiary-border: 1px solid var(--grey-40);
}
