@font-face {
  font-family: "TrashHand";
  src: url("/static/fonts/TrashHand.ttf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Margarita";
  src: url("/static/fonts/margarita.ttf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Vivian";
  src: url("/static/fonts/vivian.ttf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Marlene";
  src: url("/static/fonts/marlene.ttf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Doniyor";
  src: url("/static/fonts/doniyor.ttf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("/static/fonts/HelveticaNowText-Black.woff2") format("woff2"),
    url("/static/fonts/HelveticaNowText-Black.woff") format("woff"),
    url("/static/fonts/HelveticaNowText-Black.ttf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("/static/fonts/HelveticaNowText-BlackItalic.woff2") format("woff2"),
    url("/static/fonts/HelveticaNowText-BlackItalic.woff") format("woff"),
    url("/static/fonts/HelveticaNowText-BlackItalic.ttf");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("/static/fonts/HelveticaNowText-ExtraBold.woff2") format("woff2"),
    url("/static/fonts/HelveticaNowText-ExtraBold.woff") format("woff"),
    url("/static/fonts/HelveticaNowText-ExtraBold.ttf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("/static/fonts/HelveticaNowText-ExtraBoldItalic.woff2")
      format("woff2"),
    url("/static/fonts/HelveticaNowText-ExtraBoldItalic.woff") format("woff"),
    url("/static/fonts/HelveticaNowText-ExtraBoldItalic.ttf");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("/static/fonts/HelveticaNowText-Bold.woff2") format("woff2"),
    url("/static/fonts/HelveticaNowText-Bold.woff") format("woff"),
    url("/static/fonts/HelveticaNowText-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("/static/fonts/HelveticaNowText-BoldItalic.woff2") format("woff2"),
    url("/static/fonts/HelveticaNowText-BoldItalic.woff") format("woff"),
    url("/static/fonts/HelveticaNowText-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("/static/fonts/HelveticaNowText-Medium.woff2") format("woff2"),
    url("/static/fonts/HelveticaNowText-Medium.woff") format("woff"),
    url("/static/fonts/HelveticaNowText-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("/static/fonts/HelveticaNowText-MediumItalic.woff2") format("woff2"),
    url("/static/fonts/HelveticaNowText-MediumItalic.woff") format("woff"),
    url("/static/fonts/HelveticaNowText-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("/static/fonts/HelveticaNowText-RegIta.woff2") format("woff2"),
    url("/static/fonts/HelveticaNowText-RegIta.woff") format("woff"),
    url("/static/fonts/HelveticaNowText-RegIta.ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("/static/fonts/HelveticaNowText-Regular.woff2") format("woff2"),
    url("/static/fonts/HelveticaNowText-Regular.woff") format("woff"),
    url("/static/fonts/HelveticaNowText-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("/static/fonts/HelveticaNowText-Light.woff2") format("woff2"),
    url("/static/fonts/HelveticaNowText-Light.woff") format("woff"),
    url("/static/fonts/HelveticaNowText-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("/static/fonts/HelveticaNowText-LightItalic.woff2") format("woff2"),
    url("/static/fonts/HelveticaNowText-LightItalic.woff") format("woff"),
    url("/static/fonts/HelveticaNowText-LightItalic.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("/static/fonts/HelveticaNowText-Thin.woff2") format("woff2"),
    url("/static/fonts/HelveticaNowText-Thin.woff") format("woff"),
    url("/static/fonts/HelveticaNowText-Thin.ttf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("/static/fonts/HelveticaNowText-ThinItalic.woff2") format("woff2"),
    url("/static/fonts/HelveticaNowText-ThinItalic.woff") format("woff"),
    url("/static/fonts/HelveticaNowText-ThinItalic.ttf");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("/static/fonts/HelveticaNowText-ExtraLight.woff2") format("woff2"),
    url("/static/fonts/HelveticaNowText-ExtraLight.woff") format("woff"),
    url("/static/fonts/HelveticaNowText-ExtraLight.ttf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now";
  src: url("/static/fonts/HelveticaNowText-ExtraLightItalic.woff2")
      format("woff2"),
    url("/static/fonts/HelveticaNowText-ExtraLightItalic.woff") format("woff"),
    url("/static/fonts/HelveticaNowText-ExtraLightItalic.ttf");
  font-weight: 100;
  font-style: italic;
}
